<template>
  <div class="content">
    <div :class="['title-url']">
      <div class="title">
        <span @click="goHome()">{{ $t('Home') }}</span>
        <span class="line"></span>
        <span @click="$router.push('/newsCenter')">{{ $t('News') }}</span>
      </div>
    </div>
    <div :class="['detail-box']">
      <div class="left animate__animated animate__fadeInLeft">
        <div class="title">
          <a
            v-if="detailData.newsUrl"
            :href="detailData.newsUrl"
            target="_blank"
          >
            <h1>{{ detailData.newsTitle }}</h1>
          </a>

          <h1 v-else>{{ detailData.newsTitle }}</h1>

          <p>{{ detailData.createDate }} {{ $t('release') }}</p>
        </div>
        <div class="text-list">
          <div v-html="detailData.newsText"></div>
        </div>
      </div>
      <div class="right animate__animated animate__fadeInRight">
        <div class="title">
          <span>{{ $t('RelevantNews') }}</span>
        </div>
        <ul>
          <li
            v-for="(ele, order) in aboutNewData"
            :key="order"
            @click="changNewsFun(ele.id)"
          >
            <span>{{ ele.newsTitle }}</span>
            <span>{{ ele.createDate }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsDetail',
  data() {
    return {
      // 详情数据
      detailData: {},
      // 相关新闻数据
      aboutNewData: [],
      // 所有新闻数据
      showContentData: [],
      // 当前页码
      currentPage: 1,
      // 获取数量
      pagesize: 8
    }
  },
  mounted() {
    this.getNewsDetail()
    this.getRelatedNews()
  },
  watch: {
    '$route.params.id'(newVal, oldVal) {
      if (newVal && this.$route.name == 'newsDetail') {
        this.getNewsDetail()
        this.getRelatedNews()
      }
    }
  },
  methods: {
    getNewsDetail() {
      let formData = {
        id: this.$route.params.id
      }
      this.$axios
        .post('/officialweb/data/getGwNewsInfo', formData)
        .then((res) => {
          this.detailData = res.data
        })
        .catch(() => {})
    },
    // 获取相关新闻
    getRelatedNews() {
      let formData = {
        id: '',
        pageNo: this.currentPage,
        pageSize: this.pagesize
      }
      this.$axios
        .post('/officialweb/data/findGwNewsInfoList', formData)
        .then((res) => {
          this.showContentData = res.list.map((item) => {
            item.newsImgs = item.newsImgs
              .replace('[', '')
              .replace(']', '')
              .split(',')
            return item
          })
          this.currentChangeFun()
        })
        .catch(() => {})
    },
    currentChangeFun() {
      // // 清除上一次的数据
      this.aboutNewData = []
      // 截取相关新闻数据
      for (let i = 0; i < this.showContentData.length; i++) {
        let obj = this.showContentData[i]
        this.aboutNewData.push(obj)
      }
      // 裁剪一下相关新闻的长度
      if (this.aboutNewData.length > 10) {
        this.aboutNewData = this.aboutNewData.splice(0, 10)
      }
    },
    // 跳转路由
    changNewsFun(id) {
      this.$router.push({ name: 'newsDetail', params: { id } })
    },
    goHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: @mainSize;
  margin: 0 auto;
  padding: 0 8px;
  overflow: hidden;
  .content-center {
    margin-top: 100px;
    .card-box {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 30% 30% 30%;
      grid-gap: 60px 70px;
    }
    .el-card {
      width: 100%;
      height: 100%;
      border-radius: 0;
      position: relative;
      cursor: pointer;
      border: none;
      background-color: #f8f8f8;
      &:hover {
        // height: 99% !important;
        transform: translate3d(0, -3px, 0);
        box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.31) !important;
        -webkit-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.31) !important;
        -moz-box-shadow: 0 0 31px 4px rgba(0, 0, 0, 0.31) !important;
      }
    }
    .el-card__body {
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 280px;
      }
    }
    .text-box {
      width: 100%;
      height: 130px;
      font-size: 16px;
      color: #343434;
      text-align: left;
      padding: 20px 10px;
      // border-bottom: 1px solid @mainColor;
      overflow: hidden;
      > span {
        display: block;
        font-size: 18px;
        font-weight: bold;
        line-height: 19px;
        color: #343434;
        height: 38px;
        line-height: 30px;
        margin-bottom: 40px;
        &:hover {
          color: @mainColor;
        }
      }
    }
    .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
      box-shadow: none;
    }

    .title {
      font-size: 16px;
      color: #a8a8a8;
    }
    .bottom {
      margin-top: 4px;
      line-height: 12px;
    }
    .button {
      padding: 0;
      float: right;
      border: none;
      outline: none;
    }
  }
}
.detail-box {
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
  .left {
    width: 789px;
    overflow: hidden;
    // background-color: teal;
    .title {
      width: 100%;
      border-bottom: 1px solid #ddd;
      text-align: left;
      margin-bottom: 30px;
      h1 {
        color: @mainColor;
        font-size: 20px;
      }
      p {
        color: #a6a6a6;
        padding: 0;
        margin: 0;
      }
    }
    .text-list {
      width: 100%;
      text-align: justify;
      font-size: 16px;
      /deep/ p {
        padding: 0 !important;
        margin: 4px 0 !important;
      }
      p {
        padding: 0;
        margin: 0;
        img {
          width: 100%;
          display: block;
          padding: 0;
          margin: 10px 0;
        }
      }
    }
  }
  .right {
    width: 320px;
    .title {
      text-align: left;
      margin-bottom: 10px;
      > span {
        font-size: 24px;
      }
    }
    ul {
      width: 100%;
      li {
        cursor: pointer;
        width: 100%;
        height: 48px;
        margin-bottom: 4px;
        font-size: 14px;
        text-align: left;
        overflow: hidden;
        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: keep-all;
          &:last-child {
            color: #8a8a8a;
            font-size: 12px;
            margin-top: 2px;
          }
        }
        &:hover {
          color: @mainColor;
          span {
            &:last-child {
              color: @mainColor;
            }
          }
        }
      }
    }
  }
}
</style>
